var skillCardList = jQuery('.x-feature-list');
var skillCardHeader = jQuery('.skill-card__header');
var postTitle = jQuery('.post-strip__card-title');
var postText = jQuery('.post-strip__card-text');
var webinarCard = jQuery('.webinar-strip__card');

var options = {
    byRow: false,
    property: 'height',
    target: null,
    remove: false
}

var byRow = {
    byRow: true,
    property: 'height',
    target: null,
    remove: false
}

skillCardList.matchHeight(options);
skillCardHeader.matchHeight(options);

postTitle.matchHeight(options);
postText.matchHeight(options);

webinarCard.matchHeight(byRow);



jQuery(document).ready(function(){

	// INSERT BLOG ADVERT

	var blogContent = jQuery(".entry-content");
	var blogChildElements = blogContent.children().length;
	var halfwayElement = Math.ceil(blogChildElements / 2);
	var blogAdvert = jQuery(".article-advert");

	jQuery.fn.insertAt = function(index, element) {
		var lastIndex = this.children().size();
		if (index < 0) {
		  index = Math.max(0, lastIndex + 1 + index);
		}
		this.append(element);
		if (index < lastIndex) {
		  this.children().eq(index).before(this.children().last());
		}
		return this;
	}

	if (blogAdvert.length) {
		blogContent.insertAt(halfwayElement, blogAdvert);
	}

	var navBar = jQuery(".fc-navigation-bar");

	function stickyNavBar() {

		if (jQuery("body").hasClass("admin-bar")) {
			var spacing = 125;
		} else {
			var spacing = 95;
		}

		if (jQuery(window).width() > 980) {
			navBar.sticky({topSpacing: spacing});
		} else {
			navBar.unstick();
		}
	}

	stickyNavBar();

	jQuery(window).on('resize', function(){
		stickyNavBar();
 	});

	// CUSTOM
	var carousel = jQuery('.history-carousel__carousel');
	var activeSlide = jQuery(".history-carousel__nav-active");
	var totalSlides = jQuery(".history-carousel__nav-inactive");
	var prev = jQuery('.history-carousel__right');
	var next = jQuery('.history-carousel__left');

	carousel.on("init", function(event, slick, currentSlide){
	    activeSlide.text(parseInt(slick.currentSlide + 1));
	    totalSlides.text(parseInt(slick.slideCount));
	});

	carousel.slick({
		cssEase: 'ease-in-out',
		speed: 800,
		prevArrow: prev,
     	nextArrow: next,
	});

	carousel.on("beforeChange", function(event, slick, currentSlide){
	    activeSlide.addClass('fade-out');
	    activeSlide.removeClass('fade-in');
	});

	carousel.on("afterChange", function(event, slick, currentSlide){
	    activeSlide.text(parseInt(slick.currentSlide + 1));
	    totalSlides.text(parseInt(slick.slideCount));
	    activeSlide.addClass('fade-in');
	    activeSlide.removeClass('fade-out');
	});

	// MOBILE HERO IMAGE
   function mobileBanners() {
	   jQuery('.hero-ttl.bg-image').each(function(){
		   var imageUrl = jQuery(this).css("background-image");
		   imageUrl = imageUrl.replace('url(','').replace(')','').replace(/\"/gi, "");

		   var imageTitle = jQuery(this).find('h1').text();

		   jQuery( "<img class='mobile-hero' alt='" + imageTitle + "' src='" + imageUrl + "' />" ).insertBefore(this);

		});
	}

	mobileBanners();

	// PLACEHOLDER SUBSCRIBE
	var subscribeField = jQuery("#email-eec946c9-054a-4485-86e2-b493cdfeaebc");
    subscribeField.attr("placeholder", "Enter email address");
    
    jQuery('.button-consultation').click(function(e){
        e.preventDefault();
        var consultant = jQuery(this).data('consultant');
        jQuery('.hs-consultant input').val(consultant);
	});
	
	// VIEW BIO CLICK
	jQuery('[href=#view-bio]').click(function(e){
		e.preventDefault();
		jQuery(this).parent().find('.fc-leadership-profiles__bio').toggleClass("bio--active");
	});

	//Tabs - Program pages
	jQuery('.tabs li').click(function(){

		$this = jQuery(this); 

		if(!$this.hasClass('active')){
			var tab_index = $this.attr('data-tab-index');
			var tab_class = $this.attr('class');
	
			jQuery('.' + tab_class).removeClass('active');
	
			$this.addClass('active');
			jQuery('#' + tab_class + '-' + tab_index).addClass('active');

		} else {
			// do nothing 
		}

	})

	jQuery('.accord-container').click(function(){
		jQuery(this).addClass("accord-active");
		jQuery('.accord-container input').prop( "checked", false );
		jQuery(this).children('input').prop( "checked", true );
	});

	jQuery('.accord-active').click(function() {
		jQuery(this).children('input').prop( "checked", false );
	});

});